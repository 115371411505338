.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:2.5rem;
}

.portfolio__item {
    position: relative;
    background: var(--color-bg-variante);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    background-color: var(--color-primary-variante);
    background: transparent;
}

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3{
    margin: 1rem 0 0rem;
}

.portfolio__item h5{
    margin: 0 0 1rem;
}

.portfolio__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio__notification{
    position: absolute;
    top: 11px;
    right: -14px;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 10rem;
    background: #3949ab;
    color: white;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    box-shadow: 4px 4px 15px rgba(26, 34, 126, 0.876);
  }

/* ================= MEDIA (MEDIUM DEVICES) =================*/
@media screen and (max-width: 1024px){
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ================= MEDIA (SMALL DEVICES) =================*/
@media screen and (max-width: 600PX){
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}