 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


* {
    margin:0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/*Variables de CSS para personalizaciones generales*/
/*
:root{
    --color-bg: #1f1f38;
    --color-bg-variante: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variante: rgba(77,181,255,0.4);
    --color-blanco: #fff;
    --color-claro: rgba(255,255,255,0.6);

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    --transition: all 400ms ease;
}
*/

:root{

    --color-bg: #c8c8c8;    
    --color-bg-variante: #878787;
    --color-primary: #4a4a4a;
    --color-primary-variante: rgba(0, 0, 0, 0.996);
    --color-blanco: #fff;
    --color-claro: rgba(13, 13, 13, 0.6);

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    --transition: all 400ms ease;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-blanco);
    line-height: 1.7;
    /*background-image:<*/
}

/*================= ESTILOS GENERALES ================= */

.container{
    width: var(--container-width-lg) ;
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    padding-top: 5rem;
}

section > h2, section >h5{
    text-align: center;
    color: var(--color-claro);
}

section > h2{
    color: var(--color-primary);
    margin-bottom: 1rem; 
}

.text-light {
    color: var(--color-claro)
}

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-blanco)
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition:  var(--transition);
}

.btn:hover{
    background: var(--color-blanco);
    color: var(--color-bg);
    border-color: transparent;
}

.btn.btn-disabled{
    background: var(--color-bg-variante);
    color: var(--color-bg);
    border-color: transparent;
}

.btn.btn-disabled:hover{
    background: var(--color-bg-variante);
    color: var(--color-bg);
    border-color: transparent;
    cursor: default
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ================= ESTILOS PARA LAS TRANSICIONES DE IDIOMA LANGUAGE =================*/

.language-transition {
    opacity: 0;
    height: 0;
    width: 0;
    /*margin: auto;*/
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
}
  
.language-visible {
    opacity: 1;
    height: auto;
    width: auto;
}

.language-correct {
    margin-bottom: 0 !important;
}



/* ================= MEDIA (MEDIUM DEVICES) =================*/
@media screen and (max-width: 1024px){
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 2rem;
    }
}

/* ================= MEDIA (SMALL DEVICES) =================*/
@media screen and (max-width: 600PX){
    .container{
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}